<template>
  <div class="page-home current-page">
    <slice-zone
      wrapper="main"
      :components="components"
      :slices="homepage.data.slices"
    />
  </div>
</template>

<script setup>
import { components } from "~/slices";

import { useMenuStore } from "@/stores/menuStore.js";

const { client } = usePrismic();

const { data: homepage } = await useAsyncData("homepage", () =>
  client.getSingle("homepage")
);
const menuStore = useMenuStore();
const emit = defineEmits(["onPageChange"]);

onMounted(() => {
  menuStore.setLight(true);
  menuStore.stayLight(true);
});

onBeforeUnmount(() => {
  menuStore.stayLight(false);
});
</script>

<style src="@/assets/css/pages/home.scss" lang="scss" scoped></style>
